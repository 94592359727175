import React, { useEffect } from 'react'
import Header from '../Header/Header'
import Navbar from '../Navbar/Navbar'
import MainScreen from '../MainScreen/MainScreen'
import AllCategories from '../AllCategories/AllCategories'
import PopularProducts from '../PopularProducts/PopularProducts'
import Footer from '../Footer/Footer'
import ItemsScreen from '../ItemsScreen/ItemsScreen'
import { useNavigate } from 'react-router-dom'
import constants, { getUser } from '../../service/constants'

function Dashboard() {
  const navigate = useNavigate();
  const user = JSON.parse(getUser());
  useEffect(() => {
    const USER = localStorage.getItem(constants.USER);
    if (USER && user?.role === 'admin') {
      navigate("/adminDashboard");
    } else if (USER && user?.role === "customer") {
      navigate("/dashboard")
    }
    else if (USER && user.role === 'sales') {
      navigate('/sellerDashboard');
    }
  }, [navigate]);
  
  return (
    <>
      <Header />
      <Navbar />
      <MainScreen />
      <AllCategories />
      <PopularProducts />
      <ItemsScreen />
      <Footer />

    </>
  )
}

export default Dashboard
