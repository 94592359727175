import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import RegisterModal from './RegisterModal';
import { Link, useNavigate } from 'react-router-dom';
import useApi from '../../src/service/useApi';
import { toast } from 'react-toastify';
import { fetchDataFromAPI } from '../service/api';
import { saveUserLocally } from '../service/constants';
import Spinner from 'react-bootstrap/Spinner';
import { BsHeart, BsEye, BsEyeSlash } from "react-icons/bs";
function LoginModal(props) {
  const [registerModalShow, setRegisterModalShow] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when sign-in process starts

    const body = {
      email: email,
      password: password,
    };

    fetchDataFromAPI("/user/login", "post", body, "")
      .then((res) => {
        const userLogin = res?.data;
        if (res.status === true && userLogin) {
          saveUserLocally(JSON.stringify(res?.data));
          toast.success("Login successful!");
          navigate("/");
        } else {
          // Capture validation errors from the response and map to the form
          const validationErrors = {};
          if (res.errors) {
            res.errors.forEach(error => {
              if (error.includes("email")) {
                validationErrors.email = error;
              }
              if (error.includes("password")) {
                validationErrors.password = error;
              }
            });
            setErrors(validationErrors);
          }
          toast.error(res?.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when sign-in process ends
      });
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };


  return (
    <>
      <RegisterModal show={registerModalShow} onHide={() => setRegisterModalShow(false)} />
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="userModalLabel">Sign In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="needs-validation" noValidate onSubmit={handleSignIn}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {errors.email && <div className="invalid-feedback">{errors.email}</div>}
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Password</label>
              <div className="input-group">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type={passwordVisible ? "text" : "password"}
                  className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                  id="formSigninPassword"
                  placeholder="*****"
                  minLength={8}
                  required=""
                />
                <button
                  type="button"
                  className="input-group-text bg-transparent border-start-0"
                  onClick={togglePasswordVisibility}
                >
                  <i
                    className={`bi ${passwordVisible ? "bi-eye-fill" : "bi-eye-slash-fill"}`}
                    style={{ color: passwordVisible ? "green" : "red" }}
                  />
                  {passwordVisible ?
                    <BsEye />
                    :
                    <BsEyeSlash />
                  }

                </button>
              </div>
              {errors.password && <div className="invalid-feedback">{errors.password}</div>}
            </div>
            <div className="d-flex justify-content-between">
              <div className="form-check"></div>
              <div>
                Forgot password?
                <Link to={'/forgot-password'}> Reset It</Link>
              </div>
            </div>
            <Button type="submit" className="btn btn-primary w-100" disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Sign In'}
            </Button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="border-0 justify-content-center">
            Don't have an account?
            <Link to={'/userSignUp'} >
              <a href="#" role="button"> Sign Up</a>
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LoginModal;
